function VimeoTracker($, videoElementId, options) {

    var tracker = {
        'debug': false,

        'iframe': undefined,

        'gaTracker': undefined,

        'eventMarker': {
            'progress25': false,
            'progress50': false,
            'progress75': false,
            'videoPlayed': false,
            'videoPaused': false,
            'videoResumed': false,
            'videoSeeking': false,
            'videoCompleted': false,
            'timePercentComplete': 0
        },

        onMessageReceived: function (e) {
            if (e.origin.replace('https:', 'http:') !== "http://player.vimeo.com" || typeof tracker.gaTracker === 'undefined') {
                console.warn('Analytics tracker could not be found');
                return;
            }

            var data = JSON.parse(e.data),
                iframeEl = $("#" + data.player_id);

            switch (data.event) {
                case 'ready':
                    tracker.onReady();
                    break;

                case 'playProgress':
                    if (tracker.debug) console.log('Player progressing');

                    tracker.onPlayProgress(data.data, iframeEl);
                    break;

                case 'seek':
                    if (iframeEl.data('seek') && !tracker.eventMarker.videoSeeking) {
                        if (tracker.debug) console.log('Player seeked');

                        tracker.sendEvent(iframeEl, 'Skipped video forward or backward');
                        tracker.eventMarker.videoSeeking = true; // Avoid subsequent seek trackings
                    }
                    break;

                case 'play':
                    if (!tracker.eventMarker.videoPlayed) {
                        if (tracker.debug) console.log('Player started');

                        tracker.sendEvent(iframeEl, 'Started video');
                        tracker.eventMarker.videoPlayed = true; // Avoid subsequent play trackings
                    } else if (!tracker.eventMarker.videoResumed && tracker.eventMarker.videoPaused) {
                        if (tracker.debug) console.log('Player resumed');

                        tracker.sendEvent(iframeEl, 'Resumed video');
                        tracker.eventMarker.videoResumed = true; // Avoid subsequent resume trackings
                    }
                    break;

                case 'pause':
                    if (tracker.debug) console.log('Player paused');

                    tracker.onPause(iframeEl);
                    break;

                case 'finish':
                    if (tracker.debug) console.log('Player finished');

                    if (!tracker.eventMarker.videoCompleted) {
                        tracker.sendEvent(iframeEl, 'Completed video');
                        tracker.eventMarker.videoCompleted = true; // Avoid subsequent finish trackings
                    }
                    break;
            }
        },

        onReady: function () {
            var eliFrame = tracker.iframe;

            tracker.post('addEventListener', 'play', eliFrame);
            tracker.post('addEventListener', 'seek', eliFrame);
            tracker.post('addEventListener', 'pause', eliFrame);
            tracker.post('addEventListener', 'finish', eliFrame);
            tracker.post('addEventListener', 'playProgress', eliFrame);
        },

        onPause: function (iframeEl) {
            if (tracker.eventMarker.timePercentComplete < 99 && !tracker.eventMarker.videoPaused) {
                tracker.sendEvent(iframeEl, 'Paused video');
                tracker.eventMarker.videoPaused = true; // Avoid subsequent pause trackings
            }
        },

        // Tracking video progress
        onPlayProgress: function (data, iframeEl) {
            var progress;

            tracker.eventMarker.timePercentComplete = Math.round((data.percent) * 100); // Round to a whole number

            if (tracker.debug) console.log('Percent Complete: ' + tracker.eventMarker.timePercentComplete);

            if (!iframeEl.data('progress')) {
                return;
            }

            if (tracker.eventMarker.timePercentComplete > 24 && !tracker.eventMarker.progress25) {
                progress = 'Played video: 25%';
                tracker.eventMarker.progress25 = true;
            }

            if (tracker.eventMarker.timePercentComplete > 49 && !tracker.eventMarker.progress50) {
                progress = 'Played video: 50%';
                tracker.eventMarker.progress50 = true;
            }

            if (tracker.eventMarker.timePercentComplete > 74 && !tracker.eventMarker.progress75) {
                progress = 'Played video: 75%';
                tracker.eventMarker.progress75 = true;
            }

            if (progress) {
                tracker.sendEvent(iframeEl, progress);
            }
        },

        /**
         * Helper function for sending a message to the player
         *
         * @param action
         * @param value
         * @param {object} iframe (jQuery element)
         */
        post: function (action, value, iframe) {
            var data = {
                method: action
            };

            if (value)
                data.value = value;

            try {
                var iframeSrc = iframe.attr('src').split('?')[0];
                iframe[0].contentWindow.postMessage(JSON.stringify(data), iframeSrc);
            } catch (err) {
                if(tracker.debug) console.warn('There was an error parsing the Vimeo player');
            }
        },

        getLabel: function (iframeEl) {
            var iframeSrc = iframeEl.attr('src').split('?')[0];
            var label = iframeSrc;

            if (iframeEl.data('title')) {
                label += ' (' + iframeEl.data('title') + ')';
            } else if (iframeEl.attr('title')) {
                label += ' (' + iframeEl.attr('title') + ')';
            }
            return label;
        },

        // Send event to Classic Analytics, Universal Analytics or Google Tag Manager
        sendEvent: function (iframeEl, action) {
            var bounce = iframeEl.data('bounce');
            var label = tracker.getLabel(iframeEl);

            if (tracker.debug) console.log({
                'tracker': tracker.gaTracker,
                'action': action
            });

            switch (tracker.gaTracker) {
                case 'gtm':
                    dataLayer.push({
                        'event': 'Vimeo',
                        'eventCategory': 'Vimeo',
                        'eventAction': action,
                        'eventLabel': label,
                        'eventValue': undefined,
                        'eventNonInteraction': (bounce) ? false : true
                    });
                    break;

                case 'ua':
                    ga('send', 'event', 'Vimeo', action, label, undefined, {'nonInteraction': (bounce) ? 0 : 1});
                    break;

                case 'ga':
                    _gaq.push(['_trackEvent', 'Vimeo', action, label, undefined, (bounce) ? false : true]);
                    break;
            }
        }
    };

    // Initialize
    options = options || {};

    tracker.debug = (options.debug != null) ? options.debug : tracker.debug;

    tracker.iframe = $('#' + videoElementId);

    // Check which version of Google Analytics is used
    if (typeof ga === "function") {
        tracker.gaTracker = 'ua'; // Universal Analytics (universal.js)

        if (tracker.debug) console.log('Using Universal Analytics...');
    }

    if (typeof _gaq !== "undefined" && typeof _gaq.push === "function") {
        tracker.gaTracker = 'ga'; // Classic Analytics (ga.js)

        if (tracker.debug) console.log('Using Classic Analytics...');
    }

    if (typeof dataLayer !== "undefined" && typeof dataLayer.push === "function") {
        tracker.gaTracker = 'gtm'; // Google Tag Manager (dataLayer)

        if (tracker.debug) console.log('Using Google Tag Manager...');
    }

    // Listen for messages from the player
    if (window.addEventListener) {
        window.addEventListener('message', tracker.onMessageReceived, false);
    } else {
        window.attachEvent('onmessage', tracker.onMessageReceived, false);
    }
}
